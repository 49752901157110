import Cookies from "js-cookie";
import { routes } from "./routes";

export const getListingPayload = ({
  initial,
  sortOpt,
  skuList,
  isManual,
  cookieKeys,
  queryParam,
  componentId,
  showSpecific,
  specialCoupon,
  currentVehicle,
  manualSessionId,
}) => {
  let data = {
    continueFetch: true,
    manualLookUp: isManual,
    payload: {
      sortOpt,
      specialCoupon,
      currentVehicle,
      skuList: null,
      vFilter: null,
      sessionInfo: null,
    },
  };

  const isServer = typeof window === "undefined";
  if (!isServer) {
    const sParams = new URLSearchParams(window.location.search);
    if (sParams.has("auto_apply_coupon")) {
      data.payload.specialCoupon = sParams.get("auto_apply_coupon");
    } else {
      data.payload.specialCoupon =
        Cookies.get(cookieKeys.specialCoupon) || null;
    }
  }

  const updateSessionInfo = () => {
    data.payload.sessionInfo = currentVehicle?.session;
    data.manualLookUp = queryParam.has("make") && queryParam.has("model");
    if (!data.payload.sessionInfo && data.manualLookUp && manualSessionId) {
      data.payload.sessionInfo = { sessionId: manualSessionId };
    }
  };

  if (initial.is3BO) {
    if (showSpecific) updateSessionInfo();
  } else if (initial.isCarParts) {
    updateSessionInfo();
  }

  if (!componentId && data.payload.sessionInfo) {
    data.continueFetch = false;
  }

  if (data.continueFetch && data.payload.sessionInfo) {
    data.payload = {
      ...data.payload,
      skuList: isManual ? [] : skuList,
      vFilter: {
        component_id: componentId,
        vrm: currentVehicle?.vehicle?.vrm || "",
        make: currentVehicle?.vehicle?.make || "",
        model: currentVehicle?.vehicle?.model || "",
        session_id: data.payload.sessionInfo?.sessionId,
        year:
          (currentVehicle.vehicle?.modelVersion?.match(/\(([^)]+)\)/) ||
            [])[1] ||
          currentVehicle.vehicle?.dateOfManufacture?.match(/^(\d{4})/)[0] ||
          "",
        engine: currentVehicle?.vehicle?.engineSize || "",
        body: currentVehicle?.vehicle?.bodyStyle || "",
        hp: currentVehicle?.vehicle?.power || "",
      },
    };
  }
  return data;
};

export const updateURLParams = ({
  page,
  params,
  isManual,
  pushState,
  hasVehicle,
  replaceHistory,
  selectedFilters,
}) => {
  let queries = { page };
  if (isManual && !hasVehicle) {
    queries["ref"] = params?.ref;
    queries["make"] = params?.make;
    queries["model"] = params?.model;
  }

  if (queries.page == 1) delete queries.page;

  const isServer = typeof window === "undefined";
  if (pushState && !isServer) {
    const queryStr = new URLSearchParams(queries).toString();
    const historyURL = location.pathname + (queryStr ? `?${queryStr}` : "");
    if (replaceHistory) {
      window.history.replaceState(null, "", historyURL);
    } else {
      window.history.pushState(null, "", historyURL);
    }
  }

  let filtersObj = {};
  selectedFilters.forEach((filter) => {
    if (!filtersObj[filter.code]) filtersObj[filter.code] = [];
    filtersObj[filter.code].push(filter.value);
  });
  return filtersObj;
};

export const getEncodedQueryString = (queryParams = {}) => {
  let queryString = "";
  const firstIndex = Object.keys(queryParams)?.at(0);
  for (const key in queryParams) {
    if (queryParams.hasOwnProperty(key)) {
      const value = encodeURIComponent(queryParams[key])?.replace(/%20/g, "+");
      if (key !== firstIndex) {
        queryString += `&`;
      }
      queryString += `${key}=${value}`;
    }
  }
  return queryString;
};

export const getRedirectURL = (data, searchTerm, onResultPage = false) => {
  const totalProducts = data?.products?.total_count || 0;
  const totalCategories = data?.products?.category_count || 0;
  let redirectURL = "";

  if (data?.products?.search_trems) {
    const searchTerms = data.products.search_trems;
    const redirect = searchTerms[0]?.redirect;
    redirectURL = redirect;
  }

  if (!redirectURL) {
    if (
      totalProducts === 1 &&
      !totalCategories &&
      data?.products?.items?.length > 0
    ) {
      const result = data.products.items[0];
      redirectURL = `${routes.products}/${result.url_key}`;
    }

    if (
      totalCategories === 1 &&
      !totalProducts &&
      data?.products?.categories?.length > 0
    ) {
      const result = data.products.categories[0];
      redirectURL = `/${result.link}`;
    }

    if (!onResultPage && !redirectURL) {
      const queryString = getEncodedQueryString({ q: searchTerm });
      redirectURL = `${routes.searchResults}?${queryString}`;
    }
  }

  return { totalProducts, totalCategories, redirectURL };
};

export const mapItemStocks = (itemArr, branch) => {
  return itemArr.map((item) => {
    let info = { ...item, branch };
    const brands = info.brands.map((brand) => {
      const ndcStock = branch?.ndc?.find((i) => i.sku === brand.sku);
      const hubStock = branch?.hub?.find((i) => i.sku === brand.sku);
      const branchStock = branch?.products?.find((i) => i.sku === brand.sku);
      return {
        ...brand,
        hub_stock: hubStock ? hubStock?.stock_qty || 0 : 0,
        ndc_stock: ndcStock ? ndcStock?.stock_qty || 0 : 0,
        branch_stock: branchStock ? branchStock?.stock_qty || 0 : 0,
      };
    });
    info.brands = brands;
    return info;
  });
};
